import '../scss/vehicle-picker-widget.scss';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const vehicleService = window.BATO.Services.vehicles;
const vehiclePickerWidgetClass = "vehicle-picker-widget";
const vehiclePickerWidgetBtnClass = "vehicle-picker-widget--next";
const vehiclePickerWidgetYearClass = "vehicle-picker-widget__year"; 

let vehiclePickerWidget, vehiclePickerWidgetBtn, vehiclePickerWidgetSelectYear; 

const init = () => {
	vehiclePickerWidget = document.querySelector(`.${vehiclePickerWidgetClass}`);
	
	if (vehiclePickerWidget) {
		vehiclePickerWidgetBtn = vehiclePickerWidget.querySelector(`.${vehiclePickerWidgetBtnClass}`); 	
		vehiclePickerWidgetSelectYear = vehiclePickerWidget.querySelector(`.${vehiclePickerWidgetYearClass}`);
		
		vehiclePickerWidgetBtn.addEventListener('click', evt => {
			evt.preventDefault(); 

			window.BATO.vehicleSelector.redirect = true; 
			window.BATO.vehicleSelector.selectYear(window.BATO.vehicleSelector.year); 
			window.BATO.modal.activateModal(window.BATO.vehicleSelector, true, evt.target, true, 'modal'); 
		}); 	
		
		getYears(); 
	}
}

const getYears = () => {
	const getYears = vehicleService.getYears();
	
	getYears.then(response => {
		const years = response.data && response.data.years;
	
		if (years.length) {

			const keypressHandler = (evt) => {
				if(evt.keyCode==13){
					yearSelected(evt);
				};
			}
	
			const clickHandler = (evt) => {
					yearSelected(evt);
			}
			
			years.forEach(y => {
				const span = document.createElement('span');
				span.classList.add('option'); 
				span.setAttribute("tabindex", "0");
				span.dataset.value = span.innerText = y;

				span.addEventListener("click", clickHandler);
				span.addEventListener("keypress", keypressHandler);
				 
				vehiclePickerWidgetSelectYear.lastElementChild.appendChild(span);
			});  	

			const yearSelected = (evt) => {
				removeActive(); 
				evt.currentTarget.classList.add('active'); 
				let widgetBtn = document.querySelector('.vehicle-picker-widget--next');
				widgetBtn.focus();
				
				window.BATO.vehicleSelector.year = evt.currentTarget.dataset.value;
			};
			 
		}  
	})
}

const removeActive = () => {
	let spans = vehiclePickerWidgetSelectYear.querySelectorAll('span');
	
	spans.forEach(span => { 
		span.classList.remove('active'); 
	}); 
}

document.addEventListener("DOMContentLoaded", () => {
	init(); 
})
