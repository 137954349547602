import '../scss/hero-experience.scss';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireLastViewedStore = "tireLastViewed";
const heroExperienceClass = "hero-experience";
const tireLastViewedClass = "tire-last-viewed";
const vehiclePickerWidgetClass = "vehicle-picker-widget";
const knownVehicleClass = "known-vehicle";

const vehicleInfoLogic = () => {
	const vehicles = window.BATO?.Store?.getState().vehicles;
	const knownVehicleContainer = document.getElementsByClassName(knownVehicleClass)[0];
	const knownVehicleEl = knownVehicleContainer.getElementsByClassName('fitment')[0];
	if (vehicles.status === 'known') {
		knownVehicleEl.textContent = `${vehicles.vehicle?.year || ''}${' ' + vehicles.vehicle?.make || ''}${' ' + vehicles.vehicle?.model || ''}`;
	};
};

const init = () => {
	const tireLastViewedData = localStorage.getItem('tireLastViewed');
	const heroExperience = document.querySelector(`.${heroExperienceClass}`);
	const vehicleStatus = window.BATO?.Store?.getState().vehicles?.status;

	if (tireLastViewedData != null) {
		heroExperience.classList.add('last-viewed');
	} else if (vehicleStatus == "known") {
		heroExperience.classList.add('known-vehicle');
	} else {
		heroExperience.classList.add('unknown');
	}

	if (window.BATO?.Store) {
		window.BATO.Store.observeStore(window.BATO.Store, function (state) {
			return state.vehicles;
		}, vehicleInfoLogic);

		vehicleInfoLogic();
	}
}

document.addEventListener("DOMContentLoaded", () => {
	init();
})
