import '../scss/tire-last-viewed.scss';
import { urlLanguageCheck } from '../../../../../js/utils/urlLanguageCheck.js';

// setup window object for components to consume
if (!window.BATO) window.BATO = {};

const tireLastViewedStore = "tireLastViewed";
const tireLastViewedClass = "tire-last-viewed";
const tireLastViewedCardClass = "tire-card--summary";
const tireLastViewedCardImgClass = "cmp-tire-card__image > picture";
const tireLastViewedCardImgClassImg = "cmp-tire-card__image > picture > img";
const tireLastViewedCardHeadingClass = "subbrand-name > a";
const tireLastViewedCardDescriptionClass = "description";
const tireLastViewedWillItFitClass = "will-it-fit";
const tireLastViewedCTAClass = "a, button";
const tireLastViewedRatingClass = "ratings-app";

let tireLastViewedData,
	tireLastViewed,
	tireLastViewedCard,
	tireLastViewedImg,
	tireLastViewedCardImg,
	tireLastViewedCardHeading,
	tireLastViewedCardDescription,
	tireLastViewedCTA,
	tireLastViewedWillItFit,
	tireLastViewedRating,
	sourceDesktop,
	sourceTablet,
	sourceMobile;

const init = () => {
	tireLastViewedData = localStorage.getItem('tireLastViewed');
	tireLastViewed = document.querySelector(`.${tireLastViewedClass}`);

	if (tireLastViewed) {
		if (tireLastViewedData != null) {
			tireLastViewedCard = tireLastViewed.querySelector(`.${tireLastViewedCardClass}`);
			tireLastViewedCardImg = tireLastViewedCard.querySelector(`.${tireLastViewedCardImgClass}`);
			tireLastViewedCardHeading = tireLastViewedCard.querySelector(`.${tireLastViewedCardHeadingClass}`);
			tireLastViewedCardDescription = tireLastViewedCard.querySelector(`.${tireLastViewedCardDescriptionClass}`);
			tireLastViewedWillItFit = tireLastViewedCard.querySelector(`.${tireLastViewedWillItFitClass}`);
			tireLastViewedCTA = tireLastViewedCard.querySelectorAll(tireLastViewedCTAClass);
			tireLastViewedRating = tireLastViewedCard.querySelector(`.${tireLastViewedRatingClass}`);
			tireLastViewedImg = tireLastViewedCard.querySelector('.tire-last-viewed .cmp-tire-card__image picture img');

			sourceDesktop = tireLastViewedCardImg.querySelector("source[data-query='desktop']");
			sourceTablet = tireLastViewedCardImg.querySelector("source[data-query='tablet']");
			sourceMobile = tireLastViewedCardImg.querySelector("source[data-query='mobile']");

			tireLastViewedData = JSON.parse(tireLastViewedData);

			let productId = tireLastViewedData.productId || "";
			let productSize = tireLastViewedData.productSize || "";
			let productSizeCleaned = productSize.replaceAll(/[^\d/r]/gi, "").replace("/", "-").toLowerCase();
			let tireName = tireLastViewedData.productSubbrandName.split(" ").join("-").split("/").join("").toLowerCase();
			let site = window?.BATO?.CONFIG?.site || "bst";
			const region = window?.BATO?.CONFIG?.region || false

			const loc = window.location.hostname.toString().split(".");

			let hostName;

			if(region === 'la') {
				const domainName = loc.length > 1 ? loc[1] : loc[0];
				hostName = domainName === 'bridgestone' ? 'bridgestonetire' : domainName === 'firestone' ? 'firestonetire' : domainName;
			} else {
				hostName = loc[loc.length-2]; // e.g., "firestone", "bridgestone"
			}
			let assetsHost =  "assets." + hostName + ".com";

			let tireNameDisplay = tireLastViewedData.productSubbrandName;
			if(tireLastViewedData.productName.toUpperCase() == tireLastViewedData.productSubbrand.toUpperCase()) {
				tireNameDisplay = tireLastViewedData.productSubbrand;
			}

			sourceDesktop.srcset = "https://" + assetsHost + '/content/dam/consumer/' + site + '/shared/tires/' + tireName + '/tilted.jpg';
			sourceTablet.srcset = "https://" + assetsHost + '/content/dam/consumer/' + site + '/shared/tires/' + tireName + '/hero.jpg';
			sourceMobile.srcset = "https://" + assetsHost + '/content/dam/consumer/' + site + '/shared/tires/' + tireName + '/hero.jpg';

			// Since this component injects a placeholder image ('tire-card-last-viewed.html') that is immediately overwritten by our dynamically
			// constructed image URL the normal DAM Meta Data (that comes server-side) is not available for use - Because of that we are also
			// dynamically creating the ALT tag based on data from the User Profile
			(tireLastViewedImg) ? tireLastViewedImg.alt = "Side view of " + tireNameDisplay + " tire" : '';

			tireLastViewedCardHeading.innerHTML = tireNameDisplay;
			tireLastViewedCardDescription.innerHTML = tireLastViewedData.productReasonsToBuy;

			tireLastViewedCard.dataset.brand = tireLastViewedData.productBrand || "";
			tireLastViewedCard.dataset.productId = tireLastViewedWillItFit.dataset.productId = productId;
			tireLastViewedCard.dataset.productSize = tireLastViewedWillItFit.dataset.productSize = productSize;

			tireLastViewedRating.dataset.id = productId;
			tireLastViewedRating.dataset.brand = tireLastViewedData.productBrand;
			tireLastViewedRating.dataset.tireName = tireLastViewedData.productName;
			tireLastViewedRating.dataset.tireSubbrand = tireLastViewedData.productSubbrand;

			tireLastViewedRating.replaceChildren();

			window.dispatchEvent(new CustomEvent('collect-ratings', { bubbles: true }));

			if (tireLastViewedCTA.length >= 1) {

				let languageInUrl = urlLanguageCheck();

				tireLastViewedCTA.forEach(cta => {

					let href = cta.getAttribute("href");

					if (!href.includes("#") && href !== "/catalog") {

						href = "";

						languageInUrl ? href += '/' + languageInUrl : '';

						href += "/tire/" + tireName + "/";

						if (productSizeCleaned) {
							href += productSizeCleaned + "/";
						}

						cta.setAttribute("href", href);
						tireLastViewedRating.dataset.reviewLink = href;

					}

					cta.dataset.productId = productId;
				});
			}
		}
	}

}

document.addEventListener("DOMContentLoaded", () => {
	init();
})
